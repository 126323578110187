import { CountryModel } from "./CountryModel";

export class ProvinceModel {
    id!: string;
    name?: string = '';
    acronym?: string = '';
    country?: CountryModel;
    constructor(name?: string, acronym?: string, country?: CountryModel) {
        this.name = name;
        this.acronym = acronym;
        this.country = country;
    }
}