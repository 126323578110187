<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Cadastro de empresa</p>
  </div>
  <div class="main-content">
    <div>
      <p class="mat-headline-4">Identificação</p>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>CNPJ</mat-label>
        <input matInput [formControl]="companyCnpjFormControl" placeholder="CNPJ">
        @if (companyCnpjFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>CNPJ do empresa</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Razão Social</mat-label>
        <input matInput [formControl]="companyLegalNameFormControl" placeholder="Razão social">
        @if (companyLegalNameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Razão social do empresa</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Nome da empresa</mat-label>
        <input matInput [formControl]="companyNameFormControl" placeholder="Nome">
        @if (companyNameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Nome</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Domínio</mat-label>
        <input matInput [formControl]="companyDomainFormControl" placeholder="Domínio">
        @if (companyDomainFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Domínio de email</mat-hint>
      </mat-form-field>

    </div>
    <app-address-form></app-address-form>
  </div>
  <div class="main-footer">
    <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
      (click)="newClientActionButton()">Cadastrar</button>
    <button mat-flat-button class="footer-cancel-button" color="primary"
      [routerLink]="'/companies/list'">Cancelar</button>
  </div>
</div>