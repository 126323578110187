<div class="main">
  <div class="main-header">
    <p class="mat-headline-4">{{saudacao}}!<br>
      Hoje é {{getDataAtualPorExtenso()}}</p>
  </div>
  <div class="main-content">
    <div class="table-component">

      <div class="table-component-body">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="plate">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input form" appearance="outline">
                <mat-label>Placa</mat-label>
                <input matInput [(ngModel)]="placa" (ngModelChange)="onPlacaChange($event)">
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let dashboard"> {{dashboard.plate}} </td>
          </ng-container>

          <ng-container matColumnDef="lane">
            <th mat-header-cell *matHeaderCellDef>
              Faixa
            </th>
            <td mat-cell *matCellDef="let dashboard"> {{dashboard.lane}} </td>
          </ng-container>

          <ng-container matColumnDef="initialTimestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by initialTimestamp">
              Início da passagem
            </th>
            <td mat-cell *matCellDef="let dashboard"> {{globalService.toDate(dashboard.initialTimestamp)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="finalTimestamp">
            <th mat-header-cell *matHeaderCellDef>
              Fim da passagem
            </th>
            <td mat-cell *matCellDef="let dashboard"> {{globalService.toDate(dashboard.finalTimestamp)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="obuid">
            <th mat-header-cell *matHeaderCellDef>
              OBUIDs
            </th>
            <td mat-cell *matCellDef="let dashboard">
              <ul>
                <li *ngFor="let item of dashboard.obuid">
                  <ng-container *ngIf="item.tecnologyId !== 'camera'">
                    {{ item.tecnologyId }} <br>
                    <div class="obuidType">
                      {{ item.type }}
                    </div>
                  </ng-container>
                </li>
              </ul>
            </td>
          </ng-container>

          <ng-container matColumnDef="isLprDetected" style="text-align: center; align-items: center;">
            <th mat-header-cell *matHeaderCellDef>
              Câmera
            </th>
            <td mat-cell *matCellDef="let dashboard">
              <ng-container *ngIf="dashboard.isLprDetected == true">
                <mat-icon style="color: green;">check_circle</mat-icon>
              </ng-container>
              <ng-container *ngIf="dashboard.isLprDetected == false">
                <mat-icon style="color: red;">cancel</mat-icon>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="buttonShowDetails">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let dashboard">
              <button mat-flat-button class="paginator-button" color="primary" (click)="openDialog(dashboard)">
                Ver Detalhes</button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="table-component-footer">
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <div class="paginator">
            <mat-paginator class="demo-paginator" (page)="carregarDados($event.pageIndex, $event.pageSize)"
              [length]="passagesCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>