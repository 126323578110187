import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { InputComponent } from '../input/input.component';
import { FormControl, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageDialogBoxComponent } from '../message-dialog-box/message-dialog-box.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';

@Component({
  selector: 'app-equipment-transfer',
  standalone: true,
  imports: [MatDialogContent, MatIconModule, InputComponent, MatButtonModule],
  templateUrl: './equipment-transfer.component.html',
  styleUrl: './equipment-transfer.component.scss'
})
export class EquipmentTransferComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      equipmentId: string,
      companyId: string,
      modelId: string,
      serialNumber: string,
      softwareVersion: string,
      username: string,
      envConfig: any,
      updateEquipmentList: () => void
    },
    public dialogRef: MatDialogRef<EquipmentTransferComponent>,
    public dialog: MatDialog,
    private http: HttpClient,
    public cookieManagerService: CookieManagerService) {
  }

  companyData: { cnpj: string, corporateName: string } = {
    cnpj: '',
    corporateName: '',
  };
  currentCNPJForm = new FormControl('', [Validators.required]);
  currentcorporateNameForm = new FormControl('', [Validators.required]);
  newCNPJForm = new FormControl('', [Validators.required, Validators.minLength(14), Validators.maxLength(14)]);
  newcorporateNameForm = new FormControl('', [Validators.required]);
  newCompanyId: string = '';
  newCorporateName: string = '';
  searchDisabled: boolean = false;

  ngOnInit(): void {
    this.getCompanyByEquipment();

    this.newCNPJForm.valueChanges.subscribe(value => {
      if (value && value.length === 14) {
        this.getCompanyByCNPJ();
      } else {
        this.newCorporateName = '';
        this.newCompanyId = '';
      }
    });
  }

  getCompanyByEquipment(): void {
    const serviceRoute: string = '/companies';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<string>(
      `${this.data.envConfig.HaseCloudCoreEndpoint}${serviceRoute}/${this.data.companyId}`,
      { headers }
    ).subscribe((responsebody) => {
      this.companyData.cnpj = (responsebody as any).data.cnpj;
      this.companyData.corporateName = (responsebody as any).data.legalName;
    });
  }

  getCompanyByCNPJ(): void {
    if (!this.newCNPJForm.valid) {
      this.newCNPJForm.markAsTouched();
      this.searchDisabled = true;
    } else {
      const serviceRoute: string = '/companies/cnpj';

      const token = this.cookieManagerService.getAuthToken()
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      this.http.get<string>(
        `${this.data.envConfig.HaseCloudCoreEndpoint}${serviceRoute}/${this.newCNPJForm.value}`,
        { headers }
      ).subscribe((responsebody) => {
        this.newCorporateName = (responsebody as any).data.legalName;
        this.newCompanyId = (responsebody as any).data.id;
      });
    }
  }

  updateEquipmentCompany(): void {
    if (!this.newCNPJForm.valid) {
      this.newCNPJForm.markAsTouched();
      this.searchDisabled = true;
    } else {
      const serviceRoute: string = '/equipments';

      const token = this.cookieManagerService.getAuthToken()
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      });

      const newDataEquipment = {
        id: this.data.equipmentId,
        companyId: this.newCompanyId,
        password: "",
        confirmPassword: "",
        modelId: this.data.modelId,
        serialNumber: this.data.serialNumber,
        softwareVersion: this.data.softwareVersion,
        username: this.data.username,
        isActive: true
      }

      this.http.patch<string>(
        `${this.data.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
        newDataEquipment,
        { headers }
      ).subscribe({
        next: (responsebody) => {
          this.data.updateEquipmentList();
          this.closeModal();
        },
        error: (error) => {
          this.dialog.open(MessageDialogBoxComponent, {
            data: {
              httpResponseBase: error,
            }
          });
        }
      });
    }
  }

  isDisabled() {
    return true;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
