import { Component, Inject } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { NewClientDto } from '../../../dto/NewClientDto';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatIcon } from '@angular/material/icon';
import { UpdateEquipmentDto } from '../../../dto/UpdateEquipmentDto';
import { ENVIRONMENT } from '../../../app.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogBoxComponent } from '../../../components/message-dialog-box/message-dialog-box.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';

@Component({
  selector: 'app-update-equipment-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
    MatIcon,
  ],
  templateUrl: './update-equipment-view.component.html',
  styleUrl: './update-equipment-view.component.scss'
})
export class UpdateEquipmentViewComponent {
  hidePassword = true;
  typeOfUser = "";

  hidePasswordEvent(event: MouseEvent) {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  hideConfirmPassword = true;
  hideConfirmPasswordEvent(event: MouseEvent) {
    this.hideConfirmPassword = !this.hideConfirmPassword;
    event.stopPropagation();
  }

  public equipmentId: any;
  public companyId: any;
  public modelId: any;
  constructor(private route: ActivatedRoute, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, public cookieManagerService: CookieManagerService) {
    this.route.params.subscribe(params => this.equipmentId = params['id']);
  }

  equipmentIdFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  equipmentSerialNumberFormControl = new FormControl('', [Validators.required]);
  equipmentSoftwareVersionFormControl = new FormControl('', [Validators.required]);
  equipmentUsernameFormControl = new FormControl('', [Validators.required]);
  equipmentPasswordFormControl = new FormControl('');
  equipmentConfirmPasswordFormControl = new FormControl('');

  ngOnInit(): void {
    this.carregarDadosEquipment();
    this.typeOfUser = this.cookieManagerService.decodedToken?.role;

    if (this.typeOfUser === 'DOMAIN_ADMIN' || this.typeOfUser === 'DOMAIN_USER') {

      this.equipmentSerialNumberFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.equipmentSoftwareVersionFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
    }
  }

  carregarDadosEquipment(): void {
    const serviceRoute: string = '/equipments/';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}${this.equipmentId}`,
      { headers }
    ).subscribe((responsebody) => {
      this.equipmentIdFormControl.setValue(((responsebody as any).data as any).id)
      this.equipmentSerialNumberFormControl.setValue(((responsebody as any).data as any).serialNumber)
      this.equipmentSoftwareVersionFormControl.setValue(((responsebody as any).data as any).softwareVersion)
      this.equipmentUsernameFormControl.setValue(((responsebody as any).data as any).username)
      this.companyId = ((responsebody as any).data as any).companyId
      this.modelId = ((responsebody as any).data as any).modelId
    });
  }

  updateEquipmentActionButton() {
    const serviceRoute: string = '/equipments';

    const equipment = new UpdateEquipmentDto(
      this.equipmentIdFormControl.value ?? '',
      this.equipmentSerialNumberFormControl.value ?? '',
      this.equipmentSoftwareVersionFormControl.value ?? '',
      this.equipmentUsernameFormControl.value ?? '',
      this.equipmentPasswordFormControl.value ?? '',
      this.equipmentConfirmPasswordFormControl.value ?? ''
    );
    equipment.companyId = this.companyId;
    equipment.modelId = this.modelId;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.patch<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      equipment,
      { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Equipamento atualizado com sucesso", "Fechar", { duration: 5000 });
        this.router.navigate(['/equipments/list'])
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }
}
