import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { AddressOptions } from '../../models/address/AddressOptions';
import { IBrasilApiResponse } from '../../models/address/BrasilApiResponse';

@Injectable({
  providedIn: 'root'
})
export class BrasilApiServiceService {
  constructor(private readonly http: HttpClient) { }

  readonly endpoint = 'https://brasilapi.com.br/api/cep/v1/';

  getAddressInformationByZipCode(cep: string): Observable<AddressOptions> {
    let getAddressFromBrasilApiUrl: string = this.endpoint + cep;

    return this.http.get<Response>(getAddressFromBrasilApiUrl).pipe(
      map((response: Response) => {
        let addT = setAddressInfo(response);
        return addT;
      })
    );
  }
}

function setAddressInfo(response: Response): AddressOptions {
  let data = response as unknown as IBrasilApiResponse;

  return new AddressOptions(
    {
      'countryName': 'Brasil',
      'countryAcronym': 'BRA',
      'zipCode': data.cep,
      'provinceAcronym': data.state,
      'cityName': data.city,
      'street': data.street,
    }
  );
}