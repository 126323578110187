import { IAddressOptions } from "./AddressOptions";
import { CityModel } from "./CityModel";
import { CountryModel } from "./CountryModel";
import { ProvinceModel } from "./ProvinceModel";

export class AddressModel {
    id?: string;
    zipCode?: string;
    street?: string;
    number?: string;
    complement?: string;
    city?: CityModel;

    constructor(
        { street,
            zipCode,
            id,
            number,
            complement,
            cityName,
            provinceAcronym,
            provinceName,
            countryName,
            countryAcronym
        }: IAddressOptions
    ) {
        this.id = id;
        this.street = street;
        this.number = number;
        this.complement = complement;
        this.zipCode = zipCode;
        let country = new CountryModel(countryName, countryAcronym);
        let province = new ProvinceModel(provinceName, provinceAcronym, country)
        this.city = new CityModel(cityName, province)
    }
}