import { AddressModel } from "../models/address/AddressModel";


export class UpdateUserProfileDto {
  id!: string;
  cpf!: string;
  name!: string;
  email!: string;
  worksForCnpj!: string;
  password!: string;
  confirmPassword!: string;
  username!: string;
  address!: AddressModel;
  isActive = true;
  role: number;

  constructor(
    id: string,
    cpf: string,
    name: string,
    username: string,
    email: string,
    worksForCnpj: string,
    password: string,
    confirmPassword: string,
    address: AddressModel,
    role: number
  ) {
    this.id = id;
    this.cpf = cpf;
    this.name = name;
    this.email = email;
    this.username = username;
    this.worksForCnpj = worksForCnpj;
    this.password = password;
    this.confirmPassword = confirmPassword;
    this.address = address
    this.role = role;
  }
  toJson() {
    return {
      id: this.id,
      cpf: this.cpf,
      name: this.name,
      email: this.email,
      worksForCnpj: this.worksForCnpj,
      password: this.password,
      confirmPassword: this.confirmPassword,
      address: this.address,
      username: this.username,
      role: this.role
    };
  }
}
