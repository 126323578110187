

export interface IAddressOptions {
    street?: string,
    zipCode?: string,
    id?: string,
    number?: string,
    complement?: string,
    cityName?: string,
    provinceAcronym?: string,
    provinceName?: string,
    countryName?: string,
    countryAcronym?: string
}

export class AddressOptions implements IAddressOptions {
    street?: string;
    zipCode?: string;
    id?: string;
    number?: string;
    complement?: string;
    cityName?: string;
    provinceAcronym?: string;
    provinceName?: string;
    countryName?: string;
    countryAcronym?: string;
    constructor({ street,
        zipCode,
        id,
        number,
        complement,
        cityName,
        provinceAcronym,
        provinceName,
        countryName,
        countryAcronym
    }: IAddressOptions) {
        this.street = street;
        this.zipCode = zipCode;
        this.id = id;
        this.number = number;
        this.complement = complement;
        this.cityName = cityName;
        this.provinceAcronym = provinceAcronym;
        this.provinceName = provinceName;
        this.countryName = countryName;
        this.countryAcronym = countryAcronym;
    }
}