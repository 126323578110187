import { Component, Inject, ViewChild } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../app.component';
import { CreateCompanyDto } from '../../../dto/company/CompanyBaseDto';
import { DomainDto } from '../../../dto/domain/DomainDto';
import { MatDialog } from '@angular/material/dialog';
import { ApiResponse, MessageDialogBoxComponent, ResponseMessageDTO } from '../../../components/message-dialog-box/message-dialog-box.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';
import { AddressFormComponent } from "../../../components/address-form/address-form.component";
import { IAddressOptions } from '../../../models/address/AddressOptions';
import { AddressModel } from '../../../models/address/AddressModel';


@Component({
  selector: 'app-new-client-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
    AddressFormComponent
  ],
  templateUrl: './new-client-view.component.html',
  styleUrl: './new-client-view.component.scss'
})
export class NewClientViewComponent {
  serviceRoute: string = '/companies';
  @ViewChild(AddressFormComponent)
  private readonly addressFormComponent!: AddressFormComponent;

  constructor(
    private readonly http: HttpClient,
    @Inject(ENVIRONMENT) public envConfig: any,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly _snackBar: MatSnackBar,
    public cookieManagerService: CookieManagerService
  ) { }

  //company
  companyNameFormControl = new FormControl('', [Validators.required]);
  companyLegalNameFormControl = new FormControl('', [Validators.required]);
  companyCnpjFormControl = new FormControl('', [Validators.required]);
  companyDomainFormControl = new FormControl('', [Validators.required]);
  newAddress = new FormControl('', [Validators.required]);

  //Address
  companyStreetFormControl = new FormControl('', [Validators.required]);
  companyNumbeFormControl = new FormControl('', [Validators.required]);
  companyComplementFormControl = new FormControl('');
  companyZipCodeFormControl = new FormControl('', [Validators.required]);
  //Address.City
  companyCityNameFormControl = new FormControl('', [Validators.required]);
  //Address.City.Province
  companyProvinceNameFormControl = new FormControl('', [Validators.required]);
  companyProvinceAcronymFormControl = new FormControl('', [Validators.required]);
  //Address.City.Province.Country
  companyCountryNameFormControl = new FormControl('', [Validators.required]);
  companyCountryAcronymFormControl = new FormControl('', [Validators.required]);


  newClientActionButton() {
    let newDomain = new DomainDto(this.companyDomainFormControl.value ?? '');

    let newCompany = new CreateCompanyDto(
      {
        name: this.companyNameFormControl.value ?? '',
        legalName: this.companyLegalNameFormControl.value ?? '',
        cnpj: this.companyCnpjFormControl.value ?? '',
        domainList: [newDomain],
        address: new AddressModel(this.addressFormComponent.getAddressOptions() as IAddressOptions)
      });

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.post<ApiResponse<ResponseMessageDTO>>(
      `${this.envConfig.HaseCloudCoreEndpoint}${this.serviceRoute}`,
      newCompany, { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Cliente cadastrado com sucesso", "Fechar", { duration: 5000 });
        this.router.navigate(['/companies/list'])
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });

  }
}
