import { ProvinceModel } from "./ProvinceModel";

export class CityModel {
    id!: string;
    name?: string = '';
    province?: ProvinceModel;
    constructor(name?: string, provice?: ProvinceModel) {
        this.name = name;

        this.province = provice;
    }

}