<mat-drawer-container class="container light-theme" autosize>
  <mat-drawer #drawer class="sidenav" opened="true" disableClose="false" mode="side">
    <div class="sidenav-left-content">
      <div class="sidenav-lc-header-row">
        <app-square-logo></app-square-logo>
      </div>
      <div class="sidenav-lc-main-row">

        <!--DASHBOARD-->
        <button mat-icon-button (click)="disableSecondMenu()" [routerLink]="'dashboard'" routerLinkActive="selected"
          class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">space_dashboard</mat-icon>
        </button>
        <p class="label-sidenav-button">Passagens</p>

        <!--EQUIPAMENTOS-->
        <button mat-icon-button (click)="equipamento()" [routerLink]="'equipments'" routerLinkActive="selected"
          class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">speaker_phone</mat-icon>
        </button>
        <p class="label-sidenav-button">Equipamentos</p>

        <!--CLIENTES-->
        @if(typeOfUser == 'SYSTEM_ADMIN' || typeOfUser == 'SYSTEM_USER'){
        <button mat-icon-button (click)="cliente()" [routerLink]="'companies'" routerLinkActive="selected"
          class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">domain</mat-icon>
        </button>
        <p class="label-sidenav-button">Empresas</p>
        }

        <!--USUÁRIOS-->
        @if(typeOfUser == 'SYSTEM_ADMIN' || typeOfUser == 'DOMAIN_ADMIN'){
        <button mat-icon-button (click)="usuario()" [routerLink]="'users'" routerLinkActive="selected"
          class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">group</mat-icon>
        </button>
        <p class="label-sidenav-button">Usuários</p>
        }
        @if(typeOfUser == 'SYSTEM_ADMIN' || typeOfUser == 'DOMAIN_ADMIN'){
        <button mat-icon-button (click)="disableSecondMenu()" [routerLink]="'monitoring'" routerLinkActive="selected"
          class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">article</mat-icon>
        </button>
        <p class="label-sidenav-button">Monitoramento</p>
        }
      </div>

      <div class="sidenav-lc-footer-row">

        <!--PERFIL-->
        <button mat-icon-button [routerLink]="'profile'" class="side-button" style="margin-bottom: 15px;">
          <mat-icon color="primary" style="transform: scale(1.4);">account_circle</mat-icon>
        </button>

        <!--LOGOUT-->
        <button mat-icon-button (click)="logout()" class="side-button">
          <mat-icon color="primary" style="transform: scale(1.4);">logout</mat-icon>
        </button>
        <p class="label-sidenav-button">Logout</p>

      </div>
    </div>
  </mat-drawer>

  @if(showClientes){
  <div class="sidenav-auxiliar">
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'companies/list'" routerLinkActive="selected"
      (click)="showClientes = !showClientes">Gerenciar empresas</button><br>
    @if(typeOfUser == 'SYSTEM_ADMIN'){
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'companies/new'" routerLinkActive="selected"
      style="margin-top: 15px;" (click)="showClientes = !showClientes">Cadastrar empresa</button><br>
    }
  </div>
  }

  @if(showEquipment){
  <div class="sidenav-auxiliar">
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'equipments/list'" routerLinkActive="selected"
      (click)="showEquipment = !showEquipment">Gerenciar equipamentos</button><br>
    @if(typeOfUser == 'SYSTEM_ADMIN' || typeOfUser == 'SYSTEM_USER'){
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'equipments/new'" routerLinkActive="selected"
      style="margin-top: 15px;" (click)="showEquipment = !showEquipment">Cadastrar equipamento</button><br>
    }
  </div>
  }

  @if(showUser){
  <div class="sidenav-auxiliar">
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'users/list'" routerLinkActive="selected"
      (click)="showUser = !showUser">Gerenciar usuários</button><br>
    <button mat-button class="button-sidenav-auxiliar" [routerLink]="'users/new'" routerLinkActive="selected"
      style="margin-top: 15px;" (click)="showUser = !showUser">Cadastrar usuário</button><br>
  </div>
  }

  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>