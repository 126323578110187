<div class="main">
  <header>
    <p class="mat-headline-3">Logs do servidor</p>
  </header>

  <div class="input-container">
    <mat-form-field appearance="fill" class="date-picker-field">
      <input matInput [max]="maxDate" [matDatepicker]="picker" placeholder="Selecione uma data" (click)="picker.open()"
        readonly (dateChange)="onDateChange($event)" [formControl]="dateControl">
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button mat-button color="primary" *ngIf="isFilterActive" (click)="clearFilter()">Limpar</button>
  </div>

  <div class="main-content">
    <div class="table-component">

      <div class="table-component-body">
        <div *ngIf="isLoading" class="loading-container">
          <mat-spinner></mat-spinner>
          <p>Carregando dados...</p>
        </div>
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="name_log">
            <th mat-header-cell *matHeaderCellDef>
              Arquivo
            </th>
            <td mat-cell *matCellDef="let log"> {{log}} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="log-action">
              Ações
            </th>
            <td mat-cell *matCellDef="let log" class="log-action">
              <button mat-flat-button color="primary" class="action-button" (click)="downloadLogs(log)">
                <mat-icon>description</mat-icon>
                <span *ngIf="!loadingLogs[log]">Acessar</span>
                <span *ngIf="loadingLogs[log]">Carregando</span>
              </button>

            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="table-component-footer">
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <div class="paginator">
            <mat-paginator class="demo-paginator" (page)="carregarDados($event.pageIndex, $event.pageSize)"
              [length]="passagesCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
            </mat-paginator>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>