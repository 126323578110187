<mat-dialog-content class="modal-container">
  <div class="left-side">
    <h2 class="modal-title">Passagem</h2>
    <div class="no-image-container" *ngIf="!data.base64image">
      <mat-icon color="black">no_photography</mat-icon>
      <p class="no-image-label">Imagem não disponível</p>
    </div>
    <div class="image-vehicle" *ngIf="data.base64image">
      <img [src]="data.base64image" alt="Veículo passando pelo pórtico">
    </div>
  </div>
  <div class="right-side">
    <div class="info-container-labels">
      <p class="property-label">Placa: </p>
      <p class="value-label">{{ data.plate }}</p>
    </div>
    <div class="info-container-labels">
      <p class="property-label">Início da passagem:</p>
      <p class="value-label">{{globalService.toDate(data.initialTimestamp)}}</p>
    </div>
    <div class="info-container-labels">
      <p class="property-label">Fim da Passagem:</p>
      <p class="value-label">{{globalService.toDate(data.finalTimestamp)}}</p>
    </div>
    <div class="info-container-labels">
      <p class="property-label">Faixa:</p>
      <p class="value-label">{{data.lane}}</p>
    </div>
    <div class="info-container-labels">
      <p class="property-label">OBUIDs:</p>
      <div>
        <div class="obuid-information-container" *ngFor="let item of data.obuid">
          <div class="obuid-background" *ngIf="item.tecnologyId !== 'camera'">
            <p class="obuid-label">{{item.tecnologyId}}</p>
          </div>
          <p class="value-label" *ngIf="item.tecnologyId !== 'camera'">{{item.type}}</p>
        </div>
      </div>
    </div>
    <div class="info-container-labels">
      <p class="property-label">Equipamento:</p>
      <p class="value-label">{{ data.equipment ? data.equipment : 'Não especificado'}}</p>
    </div>
    <!--<div class="info-container-labels">
      <p class="property-label">Tipo de veículo:</p>
      <p class="value-label">{{ data.vehicleType ? data.vehicleType : 'Não detectado'}}</p>
    </div>-->
    <button mat-flat-button class="close-button" color="primary" (click)="closeModal()">Fechar</button>
  </div>
</mat-dialog-content>
