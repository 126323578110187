<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Gerenciar Empresas</p>
    @if(typeOfUser == 'SYSTEM_ADMIN'){
    <div>
      <button mat-flat-button class="paginator-button" color="primary" [routerLink]="'/companies/new'">+
        Cadastrar empresa</button>
    </div>
    }
  </div>
  <div class="main-content">
    <div class="table-component">

      <div class="table-component-body">
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input form" appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let company"> {{company.name}} </td>
          </ng-container>

          <ng-container matColumnDef="cnpj">
            <th mat-header-cell *matHeaderCellDef>
              <mat-form-field class="header-input form" appearance="outline">
                <mat-label>CNPJ</mat-label>
                <input matInput disabled>
              </mat-form-field>
            </th>
            <td mat-cell *matCellDef="let company"> {{company.cnpj}} </td>
          </ng-container>

          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef>
              Ações
            </th>
            <td mat-cell *matCellDef="let company">
              <button mat-icon-button [routerLink]="'/companies/'+company.id">
                @if(typeOfUser == 'SYSTEM_ADMIN'){
                <mat-icon>edit</mat-icon>
                }
                @if(typeOfUser == 'SYSTEM_USER'){
                <mat-icon>visibility</mat-icon>
                }
              </button>
              @if(typeOfUser == 'SYSTEM_ADMIN'){
              <button mat-icon-button (click)="openDialogDeleteActionButton(company.id, company.name)">
                <mat-icon>delete</mat-icon>
              </button>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="table-component-footer">
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <mat-divider></mat-divider>
          <div class="paginator">
            <mat-paginator class="demo-paginator" (page)="carregarDados($event.pageIndex, $event.pageSize)"
              [length]="passagesCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]">
            </mat-paginator>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>