import { AddressDto } from "./addressDto";

export class UpdateUserDto {
  id!: string;
  cpf!: string;
  name!: string;
  email!: string;
  worksForCnpj!: string;
  username!: string;
  addressId!: string;
  address!: AddressDto;
  isActive = true;
  role!: number;

  constructor(
    id: string,
    cpf: string,
    name: string,
    username: string,
    email: string,
    worksForCnpj: string,
    addressId: string,
    address: AddressDto,
    role: number
  ) {
    this.id = id;
    this.cpf = cpf;
    this.name = name;
    this.email = email;
    this.username = username;
    this.worksForCnpj = worksForCnpj;
    this.addressId = addressId;
    this.address = address
    this.role = role;
  }
  toJson() {
    return {
      id: this.id,
      cpf: this.cpf,
      name: this.name,
      email: this.email,
      worksForCnpj: this.worksForCnpj,
      addressId: this.addressId,
      address: this.address.toJson(),
      username: this.username,
      role: this.role
    };
  }
}
