<div class="main">
  <div class="main-header">
    <p class="mat-headline-3">Atualização de dados do usuário</p>
  </div>
  <div class="main-content">
    <div class="column form">
      <p class="mat-headline-4">Identificação</p>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>CPF</mat-label>
        <input matInput [formControl]="userCpfFormControl" placeholder="CPF" readonly>
        @if (userCpfFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>CPF do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Nome</mat-label>
        <input matInput [formControl]="nameFormControl" placeholder="Nome">
        @if (nameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Nome do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Username</mat-label>
        <input matInput [formControl]="userNameFormControl" placeholder="Username">
        @if (userNameFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>Username do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Tipo de usuário</mat-label>
        <mat-select [(value)]="selectedValue">
          @for (typeOfUser of options; track typeOfUser) {
          <mat-option [value]="typeOfUser.value">
            {{typeOfUser.viewValue}}
          </mat-option>
          }
        </mat-select>
        <mat-hint>Nível de acesso que o usuário terá</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>E-mail</mat-label>
        <input matInput [formControl]="userEmailFormControl" placeholder="E-mail" readonly>
        @if (userEmailFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>E-mail do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-identificacao">
        <mat-label>Empresa</mat-label>
        <input matInput [formControl]="worksForCnpjFormControl" placeholder="CNPJ da empresa" readonly>
        @if (worksForCnpjFormControl.hasError('required')) {
        <mat-error>Campo obrigatório</mat-error>
        }
        <mat-hint>CNPJ da empresa que o usuário trabalha</mat-hint>
      </mat-form-field>

    </div>
    <div class="column form">
      <p class="mat-headline-4">Endereço</p>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>CEP</mat-label>
        <input matInput placeholder="CEP" [formControl]="zipCodeFormControl" readonly>
        <mat-hint>CEP do usuario</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Cidade</mat-label>
        <input matInput placeholder="Cidade" [formControl]="cityFormControl" readonly>
        <mat-hint>Cidade do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Estado</mat-label>
        <input matInput placeholder="Estado" [formControl]="provinceFormControl" readonly>
        <mat-hint>Estado do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Sigla Estado</mat-label>
        <input matInput placeholder="Sigla Estado" [formControl]="provinceAcronymFormControl" readonly>
        <mat-hint>Sigla da estado do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>País</mat-label>
        <input matInput placeholder="País" [formControl]="countryFormControl" readonly>
        <mat-hint>País do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Sigla País</mat-label>
        <input matInput placeholder="Sigla País" [formControl]="countryAcronymFormControl" readonly>
        <mat-hint>Sigla da País do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco" style="width: 73%; padding-right: 2%;">
        <mat-label>Rua</mat-label>
        <input matInput placeholder="Rua" [formControl]="streetFormControl" readonly>
        <mat-hint>Rua do usuário</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco" style="width: 25%;">
        <mat-label>Número</mat-label>
        <input matInput placeholder="Número" [formControl]="numberFormControl" readonly>
        <mat-hint>Número</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="form-endereco">
        <mat-label>Complemento</mat-label>
        <input matInput placeholder="Complemento" [formControl]="complementFormControl" readonly>
        <mat-hint>Complemento do usuário</mat-hint>
      </mat-form-field>
    </div>

  </div>
  <div class="main-footer">
    <button mat-flat-button id="newClientActionButton" class="footer-button" color="primary"
      (click)="updateUserActionButton()">Atualizar</button>
    <button mat-flat-button class="footer-cancel-button" color="primary" [routerLink]="'/users/list'">Cancelar</button>
  </div>
</div>