import { AddressModel } from "../../models/address/AddressModel";
import { AddressDto } from "../addressDto";
import { DomainDto } from "../domain/DomainDto";

export class CompanyBaseDto {
    name: string;
    legalName: string;
    cnpj: string;
    domainList: DomainDto[];

    constructor(
        name: string,
        legalName: string,
        cnpj: string,
        domainList: DomainDto[]
    ) {
        this.name = name;
        this.legalName = legalName;
        this.cnpj = cnpj;
        this.domainList = domainList;
    }
}

export class CreateCompanyDto extends CompanyBaseDto {
    address: AddressModel;
    constructor(
        { name, legalName, cnpj, domainList, address }:
            {
                name: string,
                legalName: string,
                cnpj: string,
                domainList: DomainDto[],
                address: AddressModel
            }
    ) {
        super(name, legalName, cnpj, domainList);
        this.address = address;
    }
}

export class CompanyDto extends CompanyBaseDto {
    id!: string;
    addressId!: string;
    address!: AddressDto;
    constructor(
        { name, legalName, cnpj, domainList, address }:
            {
                name: string,
                legalName: string,
                cnpj: string,
                domainList: DomainDto[],
                address: AddressDto
            }
    ) {
        super(name, legalName, cnpj, domainList);
        this.address = address;
    }
}