import { Component, Inject } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router, RouterLink, RouterModule, RouterOutlet } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ENVIRONMENT } from '../../../app.component';
import { AddressDto, CityDto, CountryDto, ProvinceDto } from '../../../dto/addressDto';
import { CompanyDto } from '../../../dto/company/CompanyBaseDto';
import { MessageDialogBoxComponent } from '../../../components/message-dialog-box/message-dialog-box.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieManagerService } from '../../../services/TokenService/cookier-manager-service.service';


@Component({
  selector: 'app-update-client-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
  ],
  templateUrl: './update-client-view.component.html',
  styleUrl: './update-client-view.component.scss'
})
export class UpdateClientViewComponent {
  public clientId: any;
  typeOfUser = "";

  constructor(private route: ActivatedRoute, private http: HttpClient, @Inject(ENVIRONMENT) public envConfig: any, private router: Router, public dialog: MatDialog, private _snackBar: MatSnackBar, public cookieManagerService: CookieManagerService) {
    this.route.params.subscribe(params => this.clientId = params['id']);
  }

  //company
  companyIdFormControl = new FormControl('', [Validators.required]);
  companyNameFormControl = new FormControl('', [Validators.required]);
  companyLegalNameFormControl = new FormControl('', [Validators.required]);
  companyCnpjFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  newAddress = new FormControl('', [Validators.required]);

  //Address
  companyAddressIdFormControl = new FormControl('', [Validators.required]);
  companyStreetFormControl = new FormControl('', [Validators.required]);
  companyNumbeFormControl = new FormControl('', [Validators.required]);
  companyComplementFormControl = new FormControl('');
  companyZipCodeFormControl = new FormControl('', [Validators.required]);
  //Address.City
  companyCityIdFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  companyCityNameFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  //Address.City.Province
  companyProvinceNameFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  companyProvinceAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  //Address.City.Province.Country
  companyCountryNameFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  companyCountryAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);

  ngOnInit(): void {
    this.carregarDadosClient();
    this.typeOfUser = this.cookieManagerService.decodedToken?.role;

    if (this.typeOfUser != 'SYSTEM_ADMIN') {
      this.companyIdFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyNameFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyLegalNameFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyCnpjFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyAddressIdFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyStreetFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyNumbeFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.companyComplementFormControl = new FormControl({ value: '', disabled: true });
      this.companyZipCodeFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
      this.newAddress = new FormControl({ value: '', disabled: true }, [Validators.required]);
    }
  }

  carregarDadosClient(): void {
    const serviceRoute: string = '/companies/';

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}${this.clientId}`,
      { headers, observe: 'response' }
    ).subscribe((response) => {
      let responseBody = response.body;
      //company
      this.companyIdFormControl.setValue(((responseBody as any).data as any).id)
      this.companyNameFormControl.setValue(((responseBody as any).data as any).name)
      this.companyLegalNameFormControl.setValue(((responseBody as any).data as any).legalName)
      this.companyCnpjFormControl.setValue(((responseBody as any).data as any).cnpj)
      //Address
      this.companyAddressIdFormControl.setValue(((responseBody as any).data as any).address.id)
      this.companyStreetFormControl.setValue(((responseBody as any).data as any).address.street)
      this.companyNumbeFormControl.setValue(((responseBody as any).data as any).address.number)
      this.companyComplementFormControl.setValue(((responseBody as any).data as any).address.complement)
      this.companyZipCodeFormControl.setValue(((responseBody as any).data as any).address.zipCode)
      //Address.City
      this.companyCityIdFormControl.setValue(((responseBody as any).data as any).address.city.id)
      this.companyCityNameFormControl.setValue(((responseBody as any).data as any).address.city.name)
      //Address.City.Province
      this.companyProvinceNameFormControl.setValue(((responseBody as any).data as any).address.city.province.name)
      this.companyProvinceAcronymFormControl.setValue(((responseBody as any).data as any).address.city.province.acronym)
      //Address.City.Province.Country
      this.companyCountryNameFormControl.setValue(((responseBody as any).data as any).address.city.province.country.name)
      this.companyCountryAcronymFormControl.setValue(((responseBody as any).data as any).address.city.province.country.acronym)
    });
  }

  updateClientActionButton() {
    const serviceRoute: string = '/companies';

    const newCountry = new CountryDto(
      this.companyCountryNameFormControl.value ?? '',
      this.companyCountryAcronymFormControl.value ?? '',
    );

    const newProvince = new ProvinceDto(
      this.companyProvinceNameFormControl.value ?? '',
      this.companyProvinceAcronymFormControl.value ?? '',
      newCountry
    );

    const newCity = new CityDto(
      newProvince,
      this.companyCityIdFormControl.value ?? '',
    );
    newCity.id = this.companyCityIdFormControl.value ?? '';

    const newAddress = new AddressDto(
      this.companyStreetFormControl.value ?? '',
      this.companyNumbeFormControl.value ?? '',
      this.companyComplementFormControl.value ?? '',
      this.companyZipCodeFormControl.value ?? '',
      newCity
    );
    newAddress.id = this.companyAddressIdFormControl.value ?? '';
    newAddress.cityId = this.companyCityIdFormControl.value ?? '';

    const updateCompany = new CompanyDto(
      {
        name: this.companyNameFormControl.value ?? '',
        legalName: this.companyLegalNameFormControl.value ?? '',
        cnpj: this.companyCnpjFormControl.value ?? '',
        domainList: [],
        address: newAddress
      });
    updateCompany.id = this.companyIdFormControl.value ?? '';
    updateCompany.addressId = this.companyAddressIdFormControl.value ?? '';
    updateCompany.address = newAddress;

    const token = this.cookieManagerService.getAuthToken()
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Defina o tipo de conteúdo do cabeçalho
      'Authorization': `Bearer ${token}` // Adicione um cabeçalho de autorização se necessário
    });

    this.http.patch<object>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      updateCompany,
      { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Cliente atualizado com sucesso", "Fechar", { duration: 5000 });
        this.router.navigate(['/companies/list'])
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }
}
