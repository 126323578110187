import { Component, Inject, ViewChild } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterLink, RouterModule, RouterOutlet, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MessageDialogBoxComponent } from '../../components/message-dialog-box/message-dialog-box.component';
import { ENVIRONMENT } from '../../app.component';
import { MatDialog } from '@angular/material/dialog';
import { AddressFormComponent } from '../../components/address-form/address-form.component';
import { CookieManagerService } from '../../services/TokenService/cookier-manager-service.service';
import { MatSelectModule } from '@angular/material/select';
import { UpdateUserProfileDto } from '../../dto/UpdateUserProfileDto';
import { AddressModel } from '../../models/address/AddressModel';
import { IAddressOptions } from '../../models/address/AddressOptions';
import { MatSnackBar } from '@angular/material/snack-bar';

interface TypesUsers {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'profile-view',
  standalone: true,
  imports: [
    MatGridListModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatButtonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    MatTabsModule,
    MatIconModule,
    AddressFormComponent,
    MatSelectModule
  ],
  templateUrl: './profile-view.component.html',
  styleUrl: './profile-view.component.scss'
})

export class ProfileViewComponent {
  public addressId: any;
  public cityId: any;
  addressData: any = {};
  selectedValue: number = 0;
  options?: TypesUsers[];
  isDisabled = true;
  hidePassword = true;

  @ViewChild(AddressFormComponent)
  private readonly addressFormComponent!: AddressFormComponent;

  hidePasswordEvent(event: MouseEvent) {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  hideConfirmPassword = true;
  hideConfirmPasswordEvent(event: MouseEvent) {
    this.hideConfirmPassword = !this.hideConfirmPassword;
    event.stopPropagation();
  }

  constructor(
    public cookieManagerService: CookieManagerService,
    private http: HttpClient,
    @Inject(ENVIRONMENT) public envConfig: any,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {

  }

  userCpfFormControl = new FormControl('', [Validators.required]);
  nameFormControl = new FormControl('', [Validators.required]);
  userNameFormControl = new FormControl('', [Validators.required]);
  userEmailFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  worksForCnpjFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  streetFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  numberFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  complementFormControl = new FormControl({ value: '', disabled: true });
  zipCodeFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  cityFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  provinceFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  provinceAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  countryFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  countryAcronymFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  userPasswordFormControl = new FormControl('', []);
  userConfirmPasswordFormControl = new FormControl('', []);

  ngOnInit(): void {
    this.options = [
      { value: 0, viewValue: 'SYSTEM_ADMIN' },
      { value: 1, viewValue: 'SYSTEM_USER' },
      { value: 2, viewValue: 'DOMAIN_ADMIN' },
      { value: 3, viewValue: 'DOMAIN_USER' },
    ];
    this.carregarDadosUser();
  }

  carregarDadosUser(): void {
    const serviceRoute: string = '/users/';

    var token = this.cookieManagerService.authToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.get<string>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}${this.cookieManagerService.decodedToken.UserId}`,
      { headers }
    ).subscribe((responsebody) => {
      this.addressData = {
        street: ((responsebody as any).data as any).address.street,
        number: ((responsebody as any).data as any).address.number,
        zipCode: ((responsebody as any).data as any).address.zipCode,
        complement: ((responsebody as any).data as any).address.complement,
        cityName: ((responsebody as any).data as any).address.city.name,
        provinceAcronym: ((responsebody as any).data as any).address.city.province.acronym,
        countryName: ((responsebody as any).data as any).address.city.province.country.name,
      };
      this.selectedValue = ((responsebody as any).data as any).role;
      this.userCpfFormControl.setValue(((responsebody as any).data as any).cpf)
      this.nameFormControl.setValue(((responsebody as any).data as any).name)
      this.userNameFormControl.setValue(((responsebody as any).data as any).username)
      this.userEmailFormControl.setValue(((responsebody as any).data as any).email)
      this.worksForCnpjFormControl.setValue(((responsebody as any).data as any).worksForCnpj)
      this.streetFormControl.setValue(((responsebody as any).data as any).address.street)
      this.numberFormControl.setValue(((responsebody as any).data as any).address.number)
      this.complementFormControl.setValue(((responsebody as any).data as any).address.complement)
      this.zipCodeFormControl.setValue(((responsebody as any).data as any).address.zipCode)
      this.cityFormControl.setValue(((responsebody as any).data as any).address.city.name)
      this.provinceFormControl.setValue(((responsebody as any).data as any).address.city.province.name)
      this.provinceAcronymFormControl.setValue(((responsebody as any).data as any).address.city.province.acronym)
      this.countryFormControl.setValue(((responsebody as any).data as any).address.city.province.country.name)
      this.countryAcronymFormControl.setValue(((responsebody as any).data as any).address.city.province.country.acronym)
      this.addressId = ((responsebody as any).data as any).addressId
      this.cityId = ((responsebody as any).data as any).address.city.id
    });
  }

  updateUserActionButton() {
    const serviceRoute: string = '/users';


    const user = new UpdateUserProfileDto(
      this.cookieManagerService.decodedToken.UserId,
      this.userCpfFormControl.value ?? '',
      this.nameFormControl.value ?? '',
      this.userNameFormControl.value ?? '',
      this.userEmailFormControl.value ?? '',
      this.worksForCnpjFormControl.value ?? '',
      this.userPasswordFormControl.value ?? '',
      this.userConfirmPasswordFormControl.value ?? '',
      new AddressModel(this.addressFormComponent.getAddressOptions() as IAddressOptions),
      this.selectedValue
    );

    console.log(user)

    var token = this.cookieManagerService.authToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.patch<object>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}`,
      user, { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Usuário atualizado com sucesso", "Fechar", { duration: 5000 });

      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }

  deleteProfile() {
    const serviceRoute: string = '/users';

    var token = this.cookieManagerService.authToken;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    this.http.delete<object>(
      `${this.envConfig.HaseCloudCoreEndpoint}${serviceRoute}/${this.cookieManagerService.decodedToken.UserId}`,
      { headers, observe: 'response' }
    ).subscribe({
      next: (response) => {
        this._snackBar.open("Perfil deletado com sucesso", "Fechar", { duration: 2000 });
        setTimeout(() => {
          this.cookieManagerService.deleteAuthTokenWithoutDialogBox();
          this.router.navigateByUrl("login").then(() => {
            window.location.reload();
          });
        }, 2000);
      },
      error: (error) => {
        this.dialog.open(MessageDialogBoxComponent, {
          data: {
            httpResponseBase: error,
          }
        });
      }
    });
  }
}
